import { IPageMenu } from "@/interfaces";
import Head from "next/head";

type props = {
    data?: any,
    navSchemaData: IPageMenu[]
}
const addJsonSchema = (text: string, path: string) => {
    return {
        __html: `{
        "@context": "https://schema.org",
        "@type":"SiteNavigationElement",
        "URL": "${path}",
        "Name": "${text}"
      }`
    }
}
export function SchemaHead({ data=[], navSchemaData=[] }: props) {

    {
       Array.isArray(data) && navSchemaData && navSchemaData.length > 0 && navSchemaData.map((menu) => (
            <>
                {menu.pageUrl ? data.push(addJsonSchema(menu.pageName, menu.pageUrl)) : null}
                {menu.child && menu.child.length > 0 ? menu.child.map((item) => (item.child && item.child.length > 0 ? item.child.map((subItem) => (
                    subItem.pageUrl ? data.push(addJsonSchema(subItem.pageName, subItem.pageUrl)) : null)) : data.push(addJsonSchema(item.pageName, item.pageUrl)))) :
                    null}
            </>
        ))
    }

    if (!data)
        return null;

    return (
        <Head>
            {
           Array.isArray(data) ? data.length > 0 && data.map((item,index)=> <script type="application/ld+json" key={index} dangerouslySetInnerHTML={item} />) :
           <script type="application/ld+json" dangerouslySetInnerHTML={data} />
        }
        </Head>
    )
}