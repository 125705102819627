import Head from "next/head";
import { IMetaHead } from "../../../interfaces";
import {useGlobalContext} from '../../../lib/context';
import {CountryCode} from "../../../lib/constants";
import {getKeyByValue} from "../../../lib/helpers/common";
import { useRouter } from "next/router";

type props={
    metaData:IMetaHead;
}

 export function MetaHead({metaData}:props){
    const {props:{pageURL, countryCode}} = useGlobalContext(); 
    const siteURL=process.env.SITE_URL || '';
    const fullURL=pageURL.TrimTrailingSlash();
    const router = useRouter()
    const _isAePage = router?.asPath.startsWith('/ae')
    // const _ishreflangInCountry = metaData.hreflangInCountry?.split(',') || [];
    // const CheckHreflangInAllCountry = _ishreflangInCountry.includes(getKeyByValue(CountryCode,CountryCode.INR)) && _ishreflangInCountry.includes(getKeyByValue(CountryCode,CountryCode.AED));    
    const _ogImage = metaData.ogImage
    // const _hreflangNotNeeded = metaData.hreflangNotRequired 
    const _pageIndexNotReq = (metaData && metaData.isNoIndexPage) || pageURL.includes('search') || pageURL.includes('page=') 
   
    const getCanonicalUrl=()=>{
         if(metaData.pageUrl){
         return `${siteURL}${metaData.pageUrl}`
        } else
        return fullURL.split('?')[0].TrimTrailingSlash()
        } 
 
  //   const gethrefLang = (country: CountryCode) => {
  //     if (plainURL) {
  //       return (<>
  //           <link rel="alternate" hrefLang={`en-${CountryCode.INR}`} href={`${siteURL}/${CountryCode.INR}/${plainURL.split('?')[0]}`} />
  //           <link rel="alternate" hrefLang={`en-${CountryCode.AED}`} href={`${siteURL}/${CountryCode.AED}/${plainURL.split('?')[0]}`} />
  //           <link rel="alternate" hrefLang={`x-default`} href={`${siteURL}/${CountryCode.INR}/${plainURL.split('?')[0]}`} />
  //         </>)
  //     } else {
  //       return (<>
  //         <link rel="alternate" hrefLang={`en-${CountryCode.INR}`} href={siteURL} />
  //         <link rel="alternate" hrefLang={`en-${CountryCode.AED}`} href={(siteURL +"/"+CountryCode.AED)} />
  //         <link rel="alternate" hrefLang={`x-default`} href={siteURL} />
  //       </>)
  //     }
  // } 

    const getRobots=()=>{
      if(process.env.ENVIRONMENT !== 'PROD')
        return <meta name ="robots" content="noindex, nofollow, noodp, noydir" />;
      else
      {
        if(_pageIndexNotReq)
          return <meta name ="robots" content="noindex, follow, noodp, noydir" />;
        else if(_isAePage)
          return <meta name ="robots" content="noindex, nofollow" />
        else  
          return <meta name ="robots" content="index, follow, noodp, noydir" />;
      }
    }
    return(
        <Head>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=no"/>
            <title>{metaData.title}</title>
            <meta name="description" content={metaData.description} />
            <link rel="icon" href={siteURL+"/favicon.ico"} type="image/x-icon" />
            {/* {(CheckHreflangInAllCountry && !_hreflangNotNeeded) && gethrefLang(countryCode)} */}
            {/* {_ishreflangInCountry &&  (!_ishreflangInCountry?.includes(getKeyByValue(CountryCode,CountryCode.AED))) || !_hreflangNotNeeded  &&  gethrefLang(CountryCode.AED)}       */}
            <meta name="language" content={`en-${countryCode}, english`}/>
            <meta httpEquiv="Content-Language" content={`en-${countryCode}`}/>
            <meta name="author" content="Interior Company"/>
            <meta name="copyright" content='Interior Company'/>
            { getRobots() }
            <link rel="canonical" href={getCanonicalUrl()}/>
            <meta property= "og:url" content={getCanonicalUrl()} />
            <meta property= "og:title" content={metaData.title} />
            <meta property= "og:description" content={metaData.description} />
           <meta property= "og:image" content={!_ogImage ? siteURL+"/assets/images/interior-logo.svg":_ogImage} /> 
            <meta property= "og:image:height" content="37" />
            <meta property= "og:locale" content={`en_${countryCode.toUpperCase()}`} />
            <meta property= "og:site_name" content="Interior Company" />
            <meta property="og:type" content="website"/>

            {
                metaData.videoURL &&
                    <>
                        <meta property= "og:video" content={metaData.videoURL} />
                        <meta property= "og:video:url" content={metaData.videoURL} /> 
                        <meta property= "og:video:type" content="MP4" /> 
                    </>            
            }
            <meta name="msvalidate.01" content="59E5337308799F372663E433B7AFD456" />
        </Head>
    )
 }