import { useGlobalContext } from '../../../lib/context';
import style from './customSlideArrow.module.css'
type Props = {
    slideId: string,
    slideTileWebWidth: number,
    slideTileMobWidth: number,
    edges?: boolean
}
const CustomSlideArrow = ({ slideId, slideTileWebWidth, slideTileMobWidth, edges }: Props) => {
    const { props: { isMobileView } } = useGlobalContext();
    const scrolltile = (direction: string, categoryNameId: string) => {
        const productBxId: any = document.getElementById(categoryNameId)
        if (productBxId != null) {
            if (direction === "prev") {
                productBxId.scrollLeft -= isMobileView ? slideTileMobWidth : slideTileWebWidth;
            } else {
                productBxId.scrollLeft += isMobileView ? slideTileMobWidth : slideTileWebWidth;
            }
        }
    }
    return (
        <>
            <span className={edges && edges == true ? `${style.leftCustomSlide} ${style.left0} arrowBox` : `${style.leftCustomSlide} arrowBox`} onClick={() => scrolltile("prev", slideId)}>
                <i className="arrow left"></i>
            </span>
            <span className={edges && edges == true ? `${style.rightCustomSlide} ${style.right0} arrowBox` : `${style.rightCustomSlide} arrowBox`} onClick={() => scrolltile("next", slideId)} >
                <i className="arrow right"></i>
            </span>
        </>
    )
}
export default CustomSlideArrow